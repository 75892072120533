import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Container, Grid, Button, Divider } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import { I18nContext } from 'translations';

const styles = createStyles((theme) => ({
  footer: {
    padding: '30px 20px 25px',
    backgroundColor: '#fff'
  },
  marginX: {
    margin: '0 22px',
    [theme.breakpoints.down('sm')]: {
      margin: 15
    }
  }
}));

const Footer = ({ classes }) => {
  const { translate, langCode } = useContext(I18nContext);
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md" className="container-width-lg">
        <Grid container spacing={1} className="text-center-sm">
          <Grid item md={4} xs={12}>
            <Button size="small" href="https://www.facebook.com/PiboxCol?mibextid=ZbWKwL" target="_blank" rel="nofollow">
              <FacebookIcon />
            </Button>
            <Button size="small" href="https://www.instagram.com/piboxcol?igsh=MXZiYmw0YWxtcWV1Zw==" target="_blank" rel="nofollow">
              <InstagramIcon />
            </Button>
            <Button size="small" href=" https://www.tiktok.com/@piboxcol?_t=8ooVdRukXEj&_r=1" target="_blank" rel="nofollow">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27" height="27" viewBox="0 0 50 50" style={{ fill: "#545454" }}>
                <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"></path>
              </svg>
            </Button>
          </Grid>
          <Grid item md={8} xs={12} className="text-right text-center-sm">
            <Typography variant="subtitle1" color="textPrimary" component="span" className="block-sm">
              {translate('landing.footer.downloadOurApp')}
            </Typography>
            <Button href="https://play.google.com/store/apps/details?id=co.pibox&hl=es_CO" target="_blank" variant="outlined" className={`${classes.marginX} btn-rounded`} size="small" startIcon={<AndroidIcon />}>
              {translate('landing.footer.playStore')}
            </Button>
            <Button href="https://apps.apple.com/us/app/pibox/id1532537133" target="_blank" variant="outlined" className="btn-rounded" size="small" startIcon={<AppleIcon />}>
              {translate('landing.footer.appStore')}
            </Button>
          </Grid>
          <Grid item xs={12} className="text-center">
            <br />
            <Divider />
            <br />
            {langCode === 'es_mx' ?
              <Button to="/terms/mx/general-terms.pdf" target="_blank" component={Link} fullWidth size="small">
                {translate('generalTermsMx')}
              </Button>
            :
              <>
                <Button to="/terms/general-terms.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('generalTerms')}
                </Button>
                <Button to="/terms/particular-terms-express.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('particularTerms')}
                </Button>
                <Button to="/terms/particular-terms-domiciliary-services.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('particularTermsDomiciliaryServices')}
                </Button>
                <Button to="/terms/terms-and-conditions-of-corporate-use-pibox.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('termsCorporateUse')}
                </Button>
              </>
            }
            <br />
            <Button to="/terms/personal-data-treatment-policy-pibox.pdf" target="_blank" component={Link} size="small">
              {translate('landing.footer.dataProcessingPolicy')}
            </Button>
            <span> - </span>
            <Button to="/pqrs" component={Link} size="small">
              PQRS
            </Button>
            <br />
            <Typography variant="body2" color="textPrimary" style={{ marginTop: 10 }}>
              {translate('landing.footer.copyright')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Footer);