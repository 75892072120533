import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Button
} from '@material-ui/core';
import { I18nContext } from 'translations';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const GroupsTable = ({
  classes,
  data,
  getGroups,
  editGroup,
  recordsPerPage,
  userList
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getGroups(newPage);
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.settings.groups.table.id')}</TableCell>
              <TableCell>{translate('app.settings.groups.table.group')}</TableCell>
              <TableCell>{translate('app.settings.groups.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map(group => (
              <TableRow hover key={group._id}>
                <TableCell className={classes.headquartersCell}>
                  {group._id}
                </TableCell>
                <TableCell className={classes.headquartersCell}>
                  {group.name}
                </TableCell>
                <TableCell className={classes.headquartersCell}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    style={{ marginRight: 8 }}
                    onClick={() => userList(group._id)}
                  >
                    {translate('app.settings.groups.table.users')}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => editGroup(group)}
                  >
                    {translate('app.settings.groups.table.edit')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(GroupsTable, areEqual));

